import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  AsyncThunk,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';
import {
  bookingCatalogApi,
  ProductsResponse,
} from 'api/booking-catalog-service';
import * as models from 'api/models/booking-catalogs';
import { contains, equals } from 'utils/equals';
import { sortBy } from 'utils/sort';

export interface AddProductsToBookingCatalogState {
  bookingCatalog: models.BookingCatalogDetail | null;
  page: number;
  search: string;
  category: string | null;
  showInactive: boolean;
  products: models.BookingCatalogDetailInventory[];
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: AddProductsToBookingCatalogState = {
  bookingCatalog: null,
  page: 1,
  search: '',
  category: null,
  showInactive: false,
  products: [],
  loading: false,
  error: null,
};

interface GetProductsArgs {
  catalogType: string;
  bookingCatalogId: number;
}

export const getProducts: AsyncThunk<
  ProductsResponse,
  GetProductsArgs,
  { state: RootState }
> = createAsyncThunk(
  'add-products-to-booking-catalog/getProducts',
  async (args, { rejectWithValue }) => {
    try {
      const { catalogType, bookingCatalogId } = args;
      return await bookingCatalogApi.products(catalogType, bookingCatalogId);
    } catch (e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getProductsPending = createAction(getProducts.pending.type),
  getProductsFulfilled = createAction<ProductsResponse>(
    getProducts.fulfilled.type
  ),
  getProductsRejected = createAction<ProblemDetails>(getProducts.rejected.type);

const PageSize = 25;

interface SetItemPropertyArgs<T> {
  productId: number;
  value: T;
}

export const addProductsToBookingCatalogSlice = createSlice({
  name: 'add-products-to-booking-catalog',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setBookingCatalog(
      state,
      action: PayloadAction<models.BookingCatalogDetail | null>
    ) {
      state.bookingCatalog = action.payload;
    },
    setItemQuantity(
      state,
      action: PayloadAction<SetItemPropertyArgs<number | null>>
    ) {
      const { productId, value } = action.payload,
        products = state.products.slice(),
        index = products.findIndex((p) => p.productId === productId);

      if (index !== -1) {
        const product = { ...products[index], quantity: value };
        products.splice(index, 1, product);
        state.products = products;
      }
    },
    setItemQuantityMessage(
      state,
      action: PayloadAction<SetItemPropertyArgs<string | null>>
    ) {
      const { productId, value } = action.payload,
        products = state.products.slice(),
        index = products.findIndex((p) => p.productId === productId);

      if (index !== -1) {
        const product = { ...products[index], quantityMessage: value };
        products.splice(index, 1, product);
        state.products = products;
      }
    },
    setMaximumOrderQuantity(
      state,
      action: PayloadAction<SetItemPropertyArgs<number | null>>
    ) {
      const { productId, value } = action.payload,
        products = state.products.slice(),
        index = products.findIndex((p) => p.productId === productId);

      if (index !== -1) {
        const product = { ...products[index], maximumOrderQuantity: value };
        products.splice(index, 1, product);
        state.products = products;
      }
    },
    setMinimumOrderStemQuantityCuts(
      state,
      action: PayloadAction<SetItemPropertyArgs<number | null>>
    ) {
      const { productId, value } = action.payload,
        products = state.products.slice(),
        index = products.findIndex((p) => p.productId === productId);

      if (index !== -1) {
        const product = {
          ...products[index],
          minimumOrderStemQuantityCuts: value,
        };
        products.splice(index, 1, product);
        state.products = products;
      }
    },
    setAvailabilityCutoff(
      state,
      action: PayloadAction<SetItemPropertyArgs<string | null>>
    ) {
      const { productId, value } = action.payload,
        products = state.products.slice(),
        index = products.findIndex((p) => p.productId === productId);

      if (index !== -1) {
        const product = { ...products[index], availabilityCutoff: value };
        products.splice(index, 1, product);
        state.products = products;
      }
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = 1;
    },
    setCategory(state, action: PayloadAction<string | null>) {
      state.category = action.payload;
      state.page = 1;
    },
    setShowInactive(state, action: PayloadAction<boolean>) {
      state.showInactive = action.payload;
      state.page = 1;
    },
    clearState(state) {
      state.page = 1;
      state.search = '';
      state.bookingCatalog = null;
      state.loading = false;
      state.error = null;
      state.products = [];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getProductsPending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductsFulfilled, (state, action) => {
        state.page = 1;
        state.loading = false;
        state.products = action.payload.products;
        // convert to cases where necessary
        const isCuts = contains(
          state.bookingCatalog?.catalogType,
          'Cut Flower'
        );
        state.products.forEach((p) => {
          if (p.quantity) {
            p.quantity = p.quantity / (isCuts ? 1 : p.caseQuantity || 1);
          }
        });
      })
      .addCase(getProductsRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }),
});

export const {
  setBookingCatalog,
  setItemQuantity,
  setItemQuantityMessage,
  setPage,
  setSearch,
  setCategory,
  setShowInactive,
  setMaximumOrderQuantity,
  setMinimumOrderStemQuantityCuts,
  setAvailabilityCutoff,
  clearError,
  clearState,
} = addProductsToBookingCatalogSlice.actions;

export const selectProducts = (state: RootState) =>
  state.addProductsToBookingCatalog.products;
export const selectLoading = (state: RootState) =>
  state.addProductsToBookingCatalog.loading;
export const selectError = (state: RootState) =>
  state.addProductsToBookingCatalog.error;
export const selectBookingCatalog = (state: RootState) =>
  state.addProductsToBookingCatalog.bookingCatalog;
export const selectPage = (state: RootState) =>
  state.addProductsToBookingCatalog.page;
export const selectSearch = (state: RootState) =>
  state.addProductsToBookingCatalog.search;
export const selectCategory = (state: RootState) =>
  state.addProductsToBookingCatalog.category;
export const selectShowInactive = (state: RootState) =>
  state.addProductsToBookingCatalog.showInactive;
const selectProductsToAdd = createSelector(
  selectBookingCatalog,
  selectProducts,
  selectSearch,
  selectCategory,
  selectShowInactive,
  (bookingCatalog, allProducts, search, category, showInactive) => {
    const //existingProductIds = bookingCatalog?.inventory.map((i) => i.productId) || [],
      products = allProducts
        //.filter((p) => existingProductIds.indexOf(p.id) === -1)
        .filter(
          (p) =>
            !search ||
            contains(p.description1, search) ||
            contains(p.description2, search)
        )
        .filter((p) => !category || equals(p.category, category))
        .filter((p) => showInactive || p.isActive)
        .sort((a, b) => {
          if (a.id && !b.id) return -1;
          if (b.id && !a.id) return 1;
          if (a.isActive && !b.isActive) return -1;
          if (!a.isActive && b.isActive) return 1;
          if (a.categorySortOrder < b.categorySortOrder) return -1;
          if (a.categorySortOrder > b.categorySortOrder) return 1;
          if (a.description1 < b.description1) return -1;
          if (a.description1 > b.description1) return 1;
          if (a.description2 < b.description2) return -1;
          if (a.description2 > b.description2) return 1;
          if (a.colour < b.colour) return -1;
          if (a.colour > b.colour) return 1;
          return 0;
        });

    return products;
  }
);
export const selectCategories = createSelector(
  selectBookingCatalog,
  selectProducts,
  selectShowInactive,
  (bookingCatalog, allProducts, showInactive) => {
    const existingProductIds =
        bookingCatalog?.inventory.map((i) => i.productId) || [],
      categories = allProducts
        .filter((p) => existingProductIds.indexOf(p.id) === -1)
        .filter((p) => showInactive || p.isActive)
        .map((p) => ({ ...p }))
        .sort(sortBy('categorySortOrder'))
        .map((p) => p.category)
        .reduce((memo, p) => {
          if (memo.indexOf(p) === -1) {
            memo.push(p);
          }
          return memo;
        }, [] as string[]);
    return categories;
  }
);
export const selectPages = createSelector(selectProductsToAdd, (products) =>
  products.length < PageSize ? 1 : Math.ceil(products.length / PageSize)
);
export const selectPaginatedProducts = createSelector(
  selectProductsToAdd,
  selectPage,
  (products, page) => {
    if (products.length < PageSize) return products;
    const start = (page - 1) * PageSize;
    return products.slice(start, start + PageSize);
  }
);

export default addProductsToBookingCatalogSlice.reducer;
