import {
  createSlice,
  AsyncThunk,
  createAsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
import { alertApi } from 'api/alert-service';
import { OrderSummary } from 'api/models/home';
import { SalesAnalytics } from 'api/models/analytics';
import { BookingCatalogListItem } from 'api/models/booking-catalogs';
import { ConfirmedOrderListItem } from 'api/models/confirmed-orders';
import { OrderListItem } from 'api/models/orders';
import { RootState } from 'app/store';
import { IntranetHomeResponse, homeApi } from 'api/home-service';
import { ProblemDetails } from 'utils/problem-details';
import { sortBy } from 'utils/sort';
import { CustomerListItem } from 'api/models/customers';
import { AlertListResult } from 'api/models/alerts';

const sortByCreatedDate = sortBy('createdOn'),
  sortByOrderDate = sortBy('shipWeek', 'descending'),
  sortByAvailableTo = sortBy('availableTo');

interface HomeState {
  loaded: boolean;
  orderSummary: OrderSummary;
  webOrders: OrderListItem[];
  confirmedOrders: ConfirmedOrderListItem[];
  bookingCatalogs: BookingCatalogListItem[];
  plantsAnalytics: SalesAnalytics | null;
  cutsAnalytics: SalesAnalytics | null;
  favouriteCustomers: CustomerListItem[];
  alerts: AlertListResult;
}

const initialState: HomeState = {
  loaded: false,
  orderSummary: {
    shoppingCartCount: 0,
    webOrderCount: 0,
    orderCount: 0,
  },
  webOrders: [],
  confirmedOrders: [],
  bookingCatalogs: [],
  plantsAnalytics: null,
  cutsAnalytics: null,
  favouriteCustomers: [],
  alerts: {
    alerts: [],
    totalAlertCount: 0,
  },
};

export const getIntranetHome: AsyncThunk<
  IntranetHomeResponse,
  void,
  { state: RootState }
> = createAsyncThunk('home/home', async (_, { rejectWithValue }) => {
  try {
    return await homeApi.home();
  } catch (e) {
    return rejectWithValue(e as ProblemDetails);
  }
});

const getIntranetHomeFulfilled = createAction<IntranetHomeResponse>(
  getIntranetHome.fulfilled.type
);

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getIntranetHomeFulfilled, (state, { payload }) => {
        state.loaded = true;
        const {
          orderSummary,
          webOrders,
          confirmedOrders,
          bookingCatalogs,
          plantsAnalytics,
          cutsAnalytics,
          favouriteCustomers,
        } = payload;
        state.orderSummary = orderSummary;
        state.webOrders = webOrders;
        state.confirmedOrders = confirmedOrders;
        state.bookingCatalogs = bookingCatalogs;
        state.plantsAnalytics = plantsAnalytics;
        state.cutsAnalytics = cutsAnalytics;
        state.favouriteCustomers = favouriteCustomers;
      })
      .addMatcher(
        alertApi.endpoints.list.matchFulfilled,
        (state, { payload }) => {
          state.alerts = payload;
        }
      ),
});

export const selectLoaded = ({ home }: RootState) => home.loaded;
export const selectOrderSummary = ({ home }: RootState) => home.orderSummary;
export const selectWebOrders = ({ home }: RootState) =>
  home.webOrders.map((o) => ({ ...o })).sort(sortByCreatedDate);
export const selectConfirmedOrders = ({ home }: RootState) =>
  home.confirmedOrders.map((o) => ({ ...o })).sort(sortByOrderDate);
export const selectBookingCatalogs = ({ home }: RootState) =>
  home.bookingCatalogs.map((c) => ({ ...c })).sort(sortByAvailableTo);
export const selectPlantsAnalytics = ({ home }: RootState) =>
  home.plantsAnalytics;
export const selectCutsAnalytics = ({ home }: RootState) => home.cutsAnalytics;
export const selectFavouriteCustomers = ({ home }: RootState) =>
  home.favouriteCustomers;
export const selectAlerts = ({ home }: RootState) => home.alerts.alerts;
export const selectAlertCount = ({ home }: RootState) =>
  home.alerts.totalAlertCount;

export default homeSlice.reducer;
